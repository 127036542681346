import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NrjType } from '../../shared/enums/property.enums';

@Injectable()
export class EnergyService {
  constructor(private readonly http: HttpClient) {}

  public getEnergy(propertyId: number, orgId?: number): Observable<any> {
    return this.http.get<any>(this.getBaseUrl(orgId, propertyId), { withCredentials: true });
  }

  public getEnergyConsumptions(
    propertyId: number,
    nrjType: NrjType,
    step: string,
    startDate: string,
    endDate: string,
    orgId?: number,
  ): Observable<any> {
    const url = `${this.getBaseUrl(orgId, propertyId)}/${nrjType === NrjType.GRDF ? 'gas' : 'electricity'}`;

    return this.http.get<any>(url, {
      withCredentials: true,
      params: new HttpParams().append('step', step).append('start_date', startDate).append('end_date', endDate),
    });
  }

  public activateConsent(
    propertyId: number,
    nrjType: NrjType,
    meterId: string,
    shareOptions: any[],
    orgId?: number,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.getBaseUrl(orgId, propertyId) + '/consent/activate',
      { nrjType, meterId, shareOptions },
      {
        withCredentials: true,
      },
    );
  }

  public askShare(propertyId: number, orgId?: number): Observable<boolean> {
    return this.http.post<boolean>(
      this.getBaseUrl(orgId, propertyId) + '/share/ask',
      {},
      {
        withCredentials: true,
      },
    );
  }

  public askConsent(propertyId: number, nrjType: NrjType, orgId?: number): Observable<boolean> {
    return this.http.post<boolean>(
      this.getBaseUrl(orgId, propertyId) + '/consent/ask',
      { nrjType },
      {
        withCredentials: true,
      },
    );
  }

  public revokeConsent(propertyId: number, nrjType: NrjType, orgId?: number): Observable<boolean> {
    return this.http.post<boolean>(
      this.getBaseUrl(orgId, propertyId) + '/consent/revoke',
      { nrjType },
      { withCredentials: true },
    );
  }

  public denyConsent(propertyId: number, nrjType: NrjType, meterId: string, orgId?: number): Observable<boolean> {
    return this.http.post<boolean>(
      this.getBaseUrl(orgId, propertyId) + '/consent/deny',
      { nrjType, meterId },
      {
        withCredentials: true,
      },
    );
  }

  public editShareOptions(
    propertyId: number,
    nrjType: NrjType,
    shareOptions: any[],
    orgId?: number,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.getBaseUrl(orgId, propertyId) + '/consent/share',
      { nrjType, shareOptions },
      {
        withCredentials: true,
      },
    );
  }

  private getBaseUrl(orgId?: number, propertyId?: number): string {
    return `${environment.api.baseUrl}${orgId ? '/orgs/' + orgId : ''}/properties/${
      propertyId ? propertyId : ''
    }/energy`;
  }
}
