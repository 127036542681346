import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Org, User, UserOrg } from '../../shared/all.types';

@Injectable()
export class OrgService {
  private _baseUrl = `${environment.api.baseUrl}/orgs`;

  constructor(private readonly http: HttpClient) {}

  public getOrg(id: number): Observable<Org> {
    const url = `${this._baseUrl}/${id}`;

    return this.http.get<Org>(url, { withCredentials: true });
  }

  public createOrg(org: Partial<Org>): Observable<Org> {
    return this.http.post<Org>(this._baseUrl, org, { withCredentials: true });
  }

  public updateOrg(orgId: number, org: Partial<Org>): Observable<Org> {
    const url = `${this._baseUrl}/${orgId}`;

    return this.http.put<Org>(url, org, { withCredentials: true });
  }

  public uploadAvatar(formData: FormData, orgId: number): Observable<{ fileName: string; resized: boolean }> {
    const url = `${this._baseUrl}/${orgId}/upload-avatar`;

    return this.http.post<{ fileName: string; resized: boolean }>(url, formData, {
      withCredentials: true,
    });
  }

  public saveAvatar(orgId: number): Observable<Org> {
    const url = `${this._baseUrl}/${orgId}/save-avatar`;

    return this.http.post<Org>(
      url,
      {},
      {
        withCredentials: true,
      },
    );
  }

  public addMember(orgId: number, user: any): Observable<any> {
    const url = `${this._baseUrl}/${orgId}`;

    return this.http.post<Org>(url, user, { withCredentials: true });
  }

  public deleteMember(orgId: number, userId: number): Observable<any> {
    const url = `${this._baseUrl}/${orgId}/member/${userId}`;

    return this.http.delete<boolean>(url, { withCredentials: true });
  }

  public updateMember(orgId: number, userId: number, userOrg: Partial<UserOrg>): Observable<UserOrg> {
    const url = `${this._baseUrl}/${orgId}/member/${userId}`;

    return this.http.put<UserOrg>(url, userOrg, { withCredentials: true });
  }

  public inviteMember(orgId: number, userId: number): Observable<boolean> {
    const url = `${this._baseUrl}/${orgId}/invite`;

    return this.http.post<boolean>(url, { id: userId }, { withCredentials: true });
  }

  public getMembers(orgId?: number): Observable<UserOrg[]> {
    let url = this._baseUrl;

    if (orgId) {
      url += `/${orgId}`;
    }

    url += '/members';

    return this.http.get<UserOrg[]>(url, { withCredentials: true });
  }

  public getReferents(orgId: number): Observable<User[]> {
    const url = `${this._baseUrl}/${orgId}/referents`;

    return this.http.get<User[]>(url, { withCredentials: true });
  }

  public getOrgMembers(orgId: number): Observable<User[]> {
    const url = `${this._baseUrl}/${orgId}/org-members`;

    return this.http.get<User[]>(url, { withCredentials: true });
  }

  public getMainOrgAdmin(orgId: number): Observable<User> {
    const url = `${this._baseUrl}/${orgId}/main-admin`;

    return this.http.get<User>(url, { withCredentials: true });
  }

  /**
   * If orgId is defined, will get child orgs (if the org is a parent)
   * Otherwise, if the logged user is a power user, will get the list of all orgs
   */
  public getOrgs(orgId?: number): Observable<Org[]> {
    let url = this._baseUrl;

    if (orgId) {
      url += `/${orgId}/children`;
    }

    return this.http.get<Org[]>(url, { withCredentials: true });
  }
}
