import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Work } from '../../shared/all.types';

@Injectable()
export class WorkService {
  constructor(private readonly http: HttpClient) {}

  public getAllWorks(propertyId: number, orgId?: number): Observable<Work[]> {
    return this.http.get<Work[]>(this.getBaseUrl(orgId, propertyId), { withCredentials: true });
  }

  public createWork(propertyId: number, work: Partial<Work>, orgId?: number): Observable<Work> {
    return this.http.post<Work>(this.getBaseUrl(orgId, propertyId), work, {
      withCredentials: true,
    });
  }

  public updateWork(propertyId: number, workId: number, work: Partial<Work>, orgId?: number): Observable<Work> {
    return this.http.put<Work>(`${this.getBaseUrl(orgId, propertyId)}/${workId}`, work, {
      withCredentials: true,
    });
  }

  public deleteWork(propertyId: number, workId: number, orgId?: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.getBaseUrl(orgId, propertyId)}/${workId}`, {
      withCredentials: true,
    });
  }

  private getBaseUrl(orgId?: number, propertyId?: number): string {
    return `${environment.api.baseUrl}${orgId ? '/orgs/' + orgId : ''}/properties/${
      propertyId ? propertyId : ''
    }/works`;
  }
}
