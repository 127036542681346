import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@ed---interne/ng-uui-components';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, tap, throwError } from 'rxjs';
import { DocumentService } from '../cil/document/document.service';
import { EnergyService } from '../cil/energy/energy.service';
import { ParticipantService } from '../cil/participant/participant.service';
import { PropertyService } from '../cil/property/property.service';
import { WorkService } from '../cil/work/work.service';
import { PropertiesPageSize } from '../shared/all.constants';
import { DeleteReason } from '../shared/all.enums';
import {
  CrmItem,
  CrmProject,
  CrmProjectProperty,
  CrmTable,
  Document,
  Energy,
  Org,
  Pagination,
  Participant,
  Property,
  PropertyCount,
  PropertyParticipants,
  User,
  UserProperty,
  Work,
} from '../shared/all.types';
import { OrgState } from './org.state';
import { SetPropertiesPagination } from './pagination.state';

export interface PropertyStateModel {
  properties: Property[] | null;
  userProperties: UserProperty[] | null;
  participants: Participant[] | null;
  hasMoreProperties: boolean;
  property: Property | null;
  energy: Energy | null;
  documents: Document[] | null;
  works: Work[] | null;
  crmProjects: CrmProject[] | null;
  crmProject: CrmProject | null;
  isLoadingParticipants: boolean;
  relatedOrgs: Org[] | null;
  departments: { value: string; label: string }[] | null;
  creationMonths: { value: string; label: string }[] | null;
}

export class GetPaginatedProperties {
  static readonly type = 'PROPERTY/GET_PROPERTIES_BATCH';

  constructor(
    public payload?: Pagination,
    public reset?: boolean,
  ) {}
}

export class GetPropertyCounts {
  static readonly type = 'PROPERTY/GET_PROPERTY_COUNTS';
}

export class GetPropertyCountsSuccess {
  static readonly type = 'PROPERTY/GET_PROPERTY_COUNTS_SUCCESS';

  constructor(public propertyCounts: PropertyCount[]) {}
}

export class GetEnergy {
  static readonly type = 'PROPERTY/GET_ENERGY';
}

export class GetProperty {
  static readonly type = 'PROPERTY/GET_PROPERTY';

  constructor(
    public id: number,
    public orgId?: number,
  ) {}
}

export class GetParticipants {
  static readonly type = 'PROPERTY/GET_PARTICIPANTS';

  constructor(
    public id: number,
    public orgId?: number,
  ) {}
}

export class CreateProperty {
  static readonly type = 'PROPERTY/CREATE_PROPERTY';

  constructor(
    public payload: Partial<Property>,
    public orgId?: number,
  ) {}
}

export class UpdateProperty {
  static readonly type = 'PROPERTY/UPDATE_PROPERTY';

  constructor(
    public id: number,
    public payload: Partial<Property>,
  ) {}
}

export class DeleteProperty {
  static readonly type = 'PROPERTY/DELETE_PROPERTY';

  constructor(public id: number) {}
}

export class UpdateCustomFields {
  static readonly type = 'PROPERTY/UPDATE_CUSTOM_FIELDS';

  constructor(
    public propertyId: number,
    public payload: Partial<UserProperty>,
  ) {}
}

export class UpdateDocument {
  static readonly type = 'DOCUMENT/UPDATE_DOCUMENT';

  constructor(
    public id: number,
    public payload: Partial<Document>,
  ) {}
}

export class DeleteDocument {
  static readonly type = 'DOCUMENT/DELETE_DOCUMENT';

  constructor(public id: number) {}
}

export class AddParticipant {
  static readonly type = 'PROPERTY/ADD_PARTICIPANT';

  constructor(
    public propertyId: number,
    public payload: Partial<User | Participant>,
  ) {}
}

export class UpdateParticipant {
  static readonly type = 'PROPERTY/UPDATE_PARTICIPANT';

  constructor(
    public propertyId: number,
    public payload: Partial<Participant>,
  ) {}
}

export class UpdateParticipantUser {
  static readonly type = 'PROPERTY/UPDATE_PARTICIPANT_USER';

  constructor(
    public propertyId: number,
    public payload: Partial<User>,
  ) {}
}

export class DeleteParticipant {
  static readonly type = 'PROPERTY/DELETE_PARTICIPANT';

  constructor(
    public propertyId: number,
    public participantId: number,
  ) {}
}

export class DeleteParticipantUser {
  static readonly type = 'PROPERTY/DELETE_PARTICIPANT_USER';

  constructor(
    public propertyId: number,
    public userPropertyId: number,
    public deleteReason?: DeleteReason,
  ) {}
}

export class LeaveProperty {
  static readonly type = 'PROPERTY/LEAVE_PROPERTY';
}

export class GetAllDocuments {
  static readonly type = 'PROPERTY/GET_ALL_DOCUMENTS';
}

export class GetAllWorks {
  static readonly type = 'PROPERTY/GET_ALL_WORKS';
}

export class CreateWork {
  static readonly type = 'WORK/CREATE_WORK';

  constructor(
    public payload: Partial<Work>,
    public propertyId?: number,
  ) {}
}

export class UpdateWork {
  static readonly type = 'WORK/UPDATE_WORK';

  constructor(
    public id: number,
    public payload: Partial<Work>,
  ) {}
}

export class DeleteWork {
  static readonly type = 'WORK/DELETE_WORK';

  constructor(public id: number) {}
}

export class GetPropertyCrmProjects {
  static readonly type = 'PROPERTY/GET_CRM_PROJECTS';
}

export class GetPropertyCrmProject {
  static readonly type = 'PROPERTY/GET_CRM_PROJECT';

  constructor(public crmProjectId: number) {}
}

export class GetPropertyCrmTable {
  static readonly type = 'PROPERTY/GET_CRM_TABLE';

  constructor(
    public crmTableId: number,
    public pagination?: Pagination,
  ) {}
}

export class LeavePropertyCrmProject {
  static readonly type = 'PROPERTY/LEAVE_CRM_PROJECT';
}

export class SaveCrmProjectProperty {
  static readonly type = 'PROPERTY/SAVE_CRM_PROJECT_PROPERTY';

  constructor(
    public crmProjectId: number,
    public body: Partial<CrmProjectProperty>,
  ) {}
}

export class UpdateCrmPanel {
  static readonly type = 'PROPERTY/UPDATE_CRM_PANEL';

  constructor(
    public propertyId: number,
    public crmProjectId: number,
    public crmTableId: number,
    public body: any,
  ) {}
}

export class AddPropertyCrmItem {
  static readonly type = 'PROPERTY/ADD_CRM_ITEM';

  constructor(
    public propertyId: number,
    public crmProjectId: number,
    public crmTableId: number,
    public body: any,
  ) {}
}

export class UpdatePropertyCrmItem {
  static readonly type = 'PROPERTY/UPDATE_CRM_ITEM';

  constructor(
    public propertyId: number,
    public crmProjectId: number,
    public crmTableId: number,
    public crmItemId: number,
    public body: any,
  ) {}
}

export class DeletePropertyCrmItem {
  static readonly type = 'PROPERTY/DELETE_CRM_ITEM';

  constructor(
    public propertyId: number,
    public crmProjectId: number,
    public crmTableId: number,
    public crmItemId: number,
  ) {}
}

export class GetRelatedOrgs {
  static readonly type = 'PROPERTY_GET_RELATED_ORGS';

  constructor() {}
}

export class GetDepartments {
  static readonly type = 'PROPERTY_GET_DEPARTMENTS';

  constructor() {}
}

export class GetCreationMonths {
  static readonly type = 'PROPERTY_GET_CREATION_MONTHS';

  constructor() {}
}

@State<PropertyStateModel>({
  name: 'property',
  defaults: {
    properties: null,
    userProperties: null,
    participants: null,
    hasMoreProperties: false,
    property: null,
    documents: null,
    works: null,
    energy: null,
    crmProjects: null,
    crmProject: null,
    isLoadingParticipants: false,
    relatedOrgs: null,
    departments: null,
    creationMonths: null,
  },
})
@Injectable()
export class PropertyState {
  @Selector()
  public static properties(state: PropertyStateModel): Property[] | null {
    return state.properties;
  }

  @Selector()
  public static userProperties(state: PropertyStateModel): UserProperty[] | null {
    return state.userProperties;
  }

  @Selector()
  public static participants(state: PropertyStateModel): Participant[] | null {
    return state.participants;
  }

  @Selector()
  public static hasMoreProperties(state: PropertyStateModel): boolean {
    return state.hasMoreProperties;
  }

  @Selector()
  public static property(state: PropertyStateModel): Property | null {
    return state.property;
  }

  @Selector()
  public static energy(state: PropertyStateModel): Energy | null {
    return state.energy;
  }

  @Selector()
  public static documents(state: PropertyStateModel): Document[] | null {
    return state.documents;
  }

  @Selector()
  public static works(state: PropertyStateModel): Work[] | null {
    return state.works;
  }

  @Selector()
  public static crmProjects(state: PropertyStateModel): CrmProject[] | null {
    return state.crmProjects;
  }

  @Selector()
  public static crmProject(state: PropertyStateModel): CrmProject | null {
    return state.crmProject;
  }

  @Selector()
  public static isLoadingParticipants(state: PropertyStateModel): boolean {
    return state.isLoadingParticipants;
  }

  @Selector()
  public static relatedOrgs(state: PropertyStateModel): Org[] | null {
    return state.relatedOrgs;
  }

  @Selector()
  public static departments(state: PropertyStateModel): { value: string; label: string }[] | null {
    return state.departments;
  }

  @Selector()
  public static creationMonths(state: PropertyStateModel): { value: string; label: string }[] | null {
    return state.creationMonths;
  }

  constructor(
    private readonly alertService: AlertService,
    private readonly documentService: DocumentService,
    private readonly propertyService: PropertyService,
    private readonly energyService: EnergyService,
    private readonly workService: WorkService,
    private readonly participantService: ParticipantService,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  @Action(GetPropertyCrmProjects)
  public getCrmProjects(ctx: StateContext<PropertyStateModel>): Observable<CrmProject[]> {
    ctx.patchState({
      crmProjects: null,
    });

    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService.getCrmProjects(ctx.getState().property!.id!, org?.id).pipe(
      tap((result: CrmProject[]) => {
        ctx.patchState({
          crmProjects: result || [],
        });
      }),
    );
  }

  @Action(LeavePropertyCrmProject)
  public leaveCrmProject(ctx: StateContext<PropertyStateModel>): void {
    ctx.patchState({
      crmProject: null,
    });
  }

  @Action(GetPropertyCrmProject)
  public getCrmProject(ctx: StateContext<PropertyStateModel>, action: GetPropertyCrmProject): Observable<CrmProject> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService.getCrmProject(ctx.getState().property!.id!, action.crmProjectId, org?.id).pipe(
      tap((result: CrmProject) => {
        ctx.patchState({
          crmProject: result,
        });
      }),
    );
  }

  @Action(GetPropertyCrmTable)
  public getCrmTable(ctx: StateContext<PropertyStateModel>, action: GetPropertyCrmTable): Observable<CrmTable> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService
      .getCrmTable(
        ctx.getState().property!.id!,
        ctx.getState().crmProject!.id,
        action.crmTableId,
        action.pagination,
        org?.id,
      )
      .pipe(
        tap((result: CrmTable) => {
          const currentCrmProject = ctx.getState().crmProject!;
          const currentCrmTableIndex =
            currentCrmProject?.crmTables?.findIndex(
              (t) => t.id === action.crmTableId && t.crmProjectId === currentCrmProject.id,
            ) ?? -1;

          const tables = [...(currentCrmProject.crmTables || [])];

          if (currentCrmTableIndex > -1) {
            tables.splice(currentCrmTableIndex, 1);
          }

          result.pagination = action.pagination;
          tables.push(result);
          currentCrmProject.crmTables = tables;

          ctx.patchState({
            crmProject: currentCrmProject,
          });
        }),
      );
  }

  @Action(SaveCrmProjectProperty)
  public saveCrmProjectProperty(
    ctx: StateContext<PropertyStateModel>,
    action: SaveCrmProjectProperty,
  ): Observable<CrmProject> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService.saveCrmProjectProperty(
      action.body,
      ctx.getState().property!.id!,
      action.crmProjectId,
      org?.id,
    );
  }

  @Action(UpdateCrmPanel)
  public updateCrmPanel(ctx: StateContext<PropertyStateModel>, action: UpdateCrmPanel): Observable<any> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService.updatePanel(
      action.propertyId,
      org!.id!,
      action.crmProjectId,
      action.crmTableId,
      action.body,
    );
  }

  @Action(AddPropertyCrmItem)
  public addPropertyCrmItem(ctx: StateContext<PropertyStateModel>, action: AddPropertyCrmItem): Observable<any> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService.addCrmItem(
      action.propertyId,
      org!.id!,
      action.crmProjectId,
      action.crmTableId,
      action.body,
    );
  }

  @Action(UpdatePropertyCrmItem)
  public updatePropertyCrmItem(
    ctx: StateContext<PropertyStateModel>,
    action: UpdatePropertyCrmItem,
  ): Observable<CrmItem> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService.updateCrmItem(
      action.propertyId,
      org!.id!,
      action.crmProjectId,
      action.crmTableId,
      action.crmItemId,
      action.body,
    );
  }

  @Action(DeletePropertyCrmItem)
  public deleteCrmItem(ctx: StateContext<PropertyStateModel>, action: DeletePropertyCrmItem): Observable<boolean> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService.deleteCrmItem(
      action.propertyId,
      org!.id!,
      action.crmProjectId,
      action.crmTableId,
      action.crmItemId,
    );
  }

  @Action(GetAllDocuments)
  public getAllDocuments(ctx: StateContext<PropertyStateModel>): Observable<Document[]> {
    ctx.patchState({
      documents: null,
    });

    const org = this.store.selectSnapshot(OrgState.org);

    return this.documentService.getAllDocuments(ctx.getState().property!.id!, org?.id).pipe(
      tap((result: Document[]) => {
        ctx.patchState({
          documents: result || [],
        });
      }),
    );
  }

  @Action(GetPropertyCounts)
  public getPropertyCounts(ctx: StateContext<PropertyStateModel>): Observable<PropertyCount[]> {
    return this.propertyService
      .getPropertyCounts()
      .pipe(tap((result: PropertyCount[]) => ctx.dispatch(new GetPropertyCountsSuccess(result))));
  }

  @Action(GetAllWorks)
  public getAllWorks(ctx: StateContext<PropertyStateModel>): Observable<Work[]> {
    ctx.patchState({
      works: null,
    });

    const org = this.store.selectSnapshot(OrgState.org);

    return this.workService.getAllWorks(ctx.getState().property!.id!, org?.id).pipe(
      tap((result: Work[]) => {
        ctx.patchState({
          works: result || [],
        });
      }),
    );
  }

  @Action(GetPaginatedProperties)
  public getPaginatedProperties(
    ctx: StateContext<PropertyStateModel>,
    action: GetPaginatedProperties,
  ): Observable<Property[]> {
    if (!action.payload || action.reset) {
      ctx.patchState({ properties: null });
    }

    this.store.dispatch(new SetPropertiesPagination(action.payload));

    const org = this.store.selectSnapshot(OrgState.org);
    const currentProperties = ctx.getState().properties || [];

    return this.propertyService.getPaginatedProperties(action.payload, org?.id).pipe(
      tap((result: Property[]) => {
        ctx.patchState({
          properties: currentProperties.concat(result || []),
          hasMoreProperties: result.length === PropertiesPageSize,
        });
      }),
    );
  }

  @Action(GetEnergy)
  public getEnergy(ctx: StateContext<PropertyStateModel>): Observable<Energy> {
    ctx.patchState({
      energy: null,
    });

    const org = this.store.selectSnapshot(OrgState.org);

    return this.energyService.getEnergy(ctx.getState().property!.id!, org?.id).pipe(
      tap((result: any) => {
        ctx.patchState({
          energy: result,
        });
      }),
    );
  }

  @Action(GetProperty)
  public getProperty(ctx: StateContext<PropertyStateModel>, action: GetProperty): Observable<Property> {
    return this.propertyService.getProperty(action.id, action.orgId).pipe(
      tap((result: Property) => {
        ctx.patchState({
          property: result,
        });
      }),
    );
  }

  private _setParticipants(ctx: StateContext<PropertyStateModel>, propertyParticipants: PropertyParticipants): void {
    ctx.patchState({
      isLoadingParticipants: false,
    });

    const { property } = ctx.getState();

    if (property) {
      property.userProperties = propertyParticipants.userProperties;
      property.participants = propertyParticipants.participants;
    }

    ctx.patchState({
      userProperties: propertyParticipants.userProperties,
      participants: propertyParticipants.participants,
      property,
    });
  }

  @Action(GetParticipants)
  public getParticipants(
    ctx: StateContext<PropertyStateModel>,
    action: GetParticipants,
  ): Observable<PropertyParticipants> {
    ctx.patchState({
      isLoadingParticipants: true,
    });

    return this.participantService.getParticipants(action.id, action.orgId).pipe(
      tap((result: PropertyParticipants) => {
        this._setParticipants(ctx, result);
      }),
    );
  }

  @Action(CreateWork)
  public createWork(ctx: StateContext<PropertyStateModel>, action: CreateWork): Observable<Work> {
    const state = ctx.getState();
    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.workService.createWork(state.property!.id!, action.payload, orgId);
  }

  @Action(UpdateWork)
  public updateWork(ctx: StateContext<PropertyStateModel>, action: UpdateWork): Observable<Work> {
    if (!ctx.getState().property?.id) {
      throw throwError(() => new Error(''));
    }

    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.workService.updateWork(ctx.getState().property!.id!, action.id, action.payload, orgId).pipe(
      tap({
        next: () => {
          this.alertService.valid('Programme de travaux', 'Edition réussie');
          ctx.dispatch(new GetAllWorks());
        },
        error: (err) => this.alertService.error('Programme de travaux', err.error.content?.reason || 'Erreur inconnue'),
      }),
    );
  }

  @Action(DeleteWork)
  public deleteWork(ctx: StateContext<PropertyStateModel>, action: DeleteWork): Observable<boolean> {
    const state = ctx.getState();
    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.workService.deleteWork(state.property!.id!, action.id, orgId).pipe(
      tap({
        next: () => {
          this.alertService.valid('Programme de travaux', 'Suppression réussie');

          const index = state.works?.findIndex((work) => work.id === action.id) || -1;

          if (index !== -1) {
            const worksCopy = [...state.works!];

            worksCopy.splice(index, 1);
            ctx.patchState({
              works: worksCopy,
            });
          } else {
            ctx.dispatch(new GetAllWorks());
          }
        },
        error: (err) => this.alertService.error('Programme de travaux', err.error.content?.reason || 'Erreur inconnue'),
      }),
    );
  }

  @Action(CreateProperty)
  public createProperty(ctx: StateContext<PropertyStateModel>, action: CreateProperty): Observable<Property> {
    return this.propertyService.createProperty(action.payload, action.orgId).pipe(
      tap((result: Property) => {
        ctx.dispatch([new GetPropertyCounts(), new GetProperty(result.id!, action.orgId)]);

        if (action.orgId) {
          return this.router.navigate(['/app/orgs/' + action.orgId + '/properties', result.id]);
        } else {
          return this.router.navigate(['/app/properties', result.id]);
        }
      }),
    );
  }

  @Action(DeleteProperty)
  public deleteProperty(ctx: StateContext<PropertyStateModel>, action: DeleteProperty): Observable<boolean> {
    const state = ctx.getState();
    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService.deleteProperty(action.id, org?.id).pipe(
      tap({
        next: () => {
          this.alertService.valid('Logement', 'Suppression réussie');

          const index = state.properties?.findIndex((property) => property.id === action.id) || -1;

          if (index !== -1) {
            const propertiesCopy = [...state.properties!];

            propertiesCopy.splice(index, 1);
            ctx.patchState({
              properties: propertiesCopy,
            });
          } else {
            ctx.dispatch(new GetPaginatedProperties());
          }
        },
        error: (err) => this.alertService.error('Logement', err.error.content?.reason || 'Erreur inconnue'),
      }),
    );
  }

  @Action(UpdateCustomFields)
  public updateCustomFields(ctx: StateContext<PropertyStateModel>, action: UpdateCustomFields): Observable<Property> {
    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.propertyService.updateCustomFields(action.propertyId, action.payload, orgId).pipe(
      tap((result: Property) => {
        ctx.patchState({
          properties: ctx.getState().properties?.map((prop) => (prop.id === result.id ? result : prop)),
          property: result,
        });
      }),
    );
  }

  @Action(UpdateProperty)
  public updateProperty(ctx: StateContext<PropertyStateModel>, action: UpdateProperty): Observable<Property> {
    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.propertyService.updateProperty(action.id, action.payload, orgId).pipe(
      tap((result: Property) => {
        ctx.patchState({
          properties: ctx.getState().properties?.map((prop) => (prop.id === result.id ? result : prop)),
          property: result,
        });
      }),
    );
  }

  @Action(UpdateDocument)
  public updateDocument(ctx: StateContext<PropertyStateModel>, action: UpdateDocument): Observable<Document> {
    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.documentService.updateDocument(ctx.getState().property!.id!, action.id, action.payload, orgId).pipe(
      tap({
        next: () => {
          this.alertService.valid('Document', 'Edition réussie');
          ctx.dispatch(new GetAllDocuments());
        },
        error: (err) => this.alertService.error('Document', err.error.content?.reason || 'Erreur inconnue'),
      }),
    );
  }

  @Action(DeleteDocument)
  public deleteDocument(ctx: StateContext<PropertyStateModel>, action: DeleteDocument): Observable<boolean> {
    const state = ctx.getState();
    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.documentService.deleteDocument(state.property!.id!, action.id, orgId).pipe(
      tap({
        next: () => {
          this.alertService.valid('Document', 'Suppression réussie');

          const index = state.documents?.findIndex((document) => document.id === action.id) || -1;

          if (index !== -1) {
            const documentsCopy = [...state.documents!];

            documentsCopy.splice(index, 1);
            ctx.patchState({
              documents: documentsCopy,
            });
          } else {
            ctx.dispatch(new GetAllDocuments());
          }
        },
        error: (err) => this.alertService.error('Document', err.error.content?.reason || 'Erreur inconnue'),
      }),
    );
  }

  @Action(AddParticipant)
  public addParticipant(
    ctx: StateContext<PropertyStateModel>,
    action: AddParticipant,
  ): Observable<PropertyParticipants> {
    ctx.patchState({
      isLoadingParticipants: true,
    });

    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.participantService
      .addParticipant(action.propertyId, action.payload, orgId)
      .pipe(tap((result) => this._setParticipants(ctx, result)));
  }

  @Action(UpdateParticipant)
  public updateParticipant(
    ctx: StateContext<PropertyStateModel>,
    action: UpdateParticipant,
  ): Observable<PropertyParticipants> {
    ctx.patchState({
      isLoadingParticipants: true,
    });

    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.participantService
      .updateParticipant(action.propertyId, action.payload, orgId)
      .pipe(tap((result) => this._setParticipants(ctx, result)));
  }

  @Action(UpdateParticipantUser)
  public updateParticipantUser(
    ctx: StateContext<PropertyStateModel>,
    action: UpdateParticipantUser,
  ): Observable<PropertyParticipants> {
    ctx.patchState({
      isLoadingParticipants: true,
    });

    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.propertyService
      .updateParticipant(action.propertyId, action.payload, orgId)
      .pipe(tap((result) => this._setParticipants(ctx, result)));
  }

  @Action(DeleteParticipant)
  public deleteParticipant(
    ctx: StateContext<PropertyStateModel>,
    action: DeleteParticipant,
  ): Observable<PropertyParticipants> {
    ctx.patchState({
      isLoadingParticipants: true,
    });

    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.participantService
      .deleteParticipant(action.propertyId, action.participantId, orgId)
      .pipe(tap((result) => this._setParticipants(ctx, result)));
  }

  @Action(DeleteParticipantUser)
  public deleteParticipantUser(
    ctx: StateContext<PropertyStateModel>,
    action: DeleteParticipantUser,
  ): Observable<PropertyParticipants> {
    ctx.patchState({
      isLoadingParticipants: true,
    });

    const orgId = this.store.selectSnapshot(OrgState.org)?.id;

    return this.propertyService
      .deleteParticipant(action.propertyId, action.userPropertyId, orgId, action.deleteReason)
      .pipe(tap((result) => this._setParticipants(ctx, result)));
  }

  @Action(LeaveProperty)
  public leaveProperty(ctx: StateContext<PropertyStateModel>): void {
    ctx.patchState({
      property: null,
      energy: null,
      documents: [],
      works: [],
    });
  }

  @Action(GetRelatedOrgs)
  public getRelatedOrgs(ctx: StateContext<PropertyStateModel>): Observable<Org[]> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService.getRelatedOrgs(org?.id).pipe(
      tap((result) => {
        ctx.patchState({
          relatedOrgs: result,
        });
      }),
    );
  }

  @Action(GetDepartments)
  public getDepartments(ctx: StateContext<PropertyStateModel>): Observable<{ value: string; label: string }[]> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService.getDepartments(org?.id).pipe(
      tap((result) => {
        ctx.patchState({
          departments: result,
        });
      }),
    );
  }

  @Action(GetCreationMonths)
  public getCreationMonths(ctx: StateContext<PropertyStateModel>): Observable<{ value: string; label: string }[]> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.propertyService.getCreationMonths(org?.id).pipe(
      tap((result) => {
        ctx.patchState({
          creationMonths: result,
        });
      }),
    );
  }
}
