import { IConfig } from 'ngx-mask';

export const DocumentConstants = {
  DescriptionMaxLength: 50, // 50 characters length
  MaxFileSize: 50000000, // 50Mo in bytes
};

export const LayerPopupOptions = {
  Editing: {
    title: 'Annuler les modifications',
    htmlContent: 'Voulez-vous quitter et annuler les modifications effectuées ?',
    cancelButtonText: "Poursuivre l'édition",
    confirmButtonText: 'Quitter',
  },
  Creating: {
    title: 'Annuler les modifications',
    htmlContent: 'Voulez-vous quitter et annuler les modifications effectuées ?',
    cancelButtonText: 'Poursuivre la création',
    confirmButtonText: 'Quitter',
  },
};

// Based on options from https://www.npmjs.com/package/filesize
export const FilesizeOptions = {
  symbols: {
    B: 'octets',
    kB: 'Ko',
    MB: 'Mo',
  },
};

export const DebounceDelay = 250;
export const PropertiesPageSize = 100;
export const UnknownLabel = 'Inconnu';

export const CrmItemsPerPage = 20;

/**
 * Note: Using custom ngx-mask patterns will override default ones so you will have to re-add them there
 */
export const customMaskPatterns: IConfig['patterns'] = {
  // Only uppercase letters and numbers
  X: {
    pattern: new RegExp('[A-Z0-9]'),
    symbol: 'X',
  },
  // Only uppercase letters
  U: {
    pattern: new RegExp('[A-Z]'),
    symbol: 'U',
  },
};

export const VirtualUserPropertyId = -1;

export const DefaultPagination = {
  page: 1,
  orderBy: {
    field: 'creationDate',
    order: -1,
  },
};
