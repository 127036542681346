import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { DefaultPagination } from '../shared/all.constants';
import { Pagination } from '../shared/all.types';

export interface PaginationStateModel {
  properties: Pagination | null;
  crmTables: { [crmTableId: number]: Pagination | null };
}

export class SetPropertiesPagination {
  static readonly type = 'PAGINATION/SET_PROPERTIES';

  constructor(public payload?: Pagination) {}
}

export class SetCrmTablePagination {
  static readonly type = 'PAGINATION/SET_CRM_TABLE';

  constructor(
    public crmTableId: number,
    public payload?: Pagination,
  ) {}
}

@State<PaginationStateModel>({
  name: 'pagination',
  defaults: {
    properties: null,
    crmTables: {},
  },
})
@Injectable()
export class PaginationState {
  @Selector()
  public static properties(state: PaginationStateModel): Pagination | null {
    return state.properties;
  }

  static crmTable(crmTableId: number): (state: PaginationStateModel) => Pagination {
    return createSelector([PaginationState], (state: PaginationStateModel) => {
      return state.crmTables[crmTableId] || DefaultPagination;
    });
  }

  @Action(SetPropertiesPagination)
  public setPropertiesPagination(ctx: StateContext<PaginationStateModel>, action: SetPropertiesPagination): void {
    ctx.patchState({ properties: action.payload || null });
  }

  @Action(SetCrmTablePagination)
  public setCrmTablePagination(ctx: StateContext<PaginationStateModel>, action: SetCrmTablePagination): void {
    const crmTables = ctx.getState().crmTables;
    crmTables[action.crmTableId] = action.payload || null;
    ctx.patchState({ crmTables });
  }
}
