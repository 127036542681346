import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PropertiesPageSize } from '../../shared/all.constants';
import { DeleteReason } from '../../shared/all.enums';
import {
  CrmItem,
  CrmProject,
  CrmProjectProperty,
  CrmTable,
  ExportJob,
  Org,
  Pagination,
  Property,
  PropertyCount,
  PropertyParticipants,
  User,
  UserProperty,
} from '../../shared/all.types';
import { FileFieldValue } from '../../shared/crm.helpers';

@Injectable()
export class PropertyService {
  private _baseUrl = `${environment.api.baseUrl}/properties`;

  constructor(private readonly http: HttpClient) {}

  public getPaginatedProperties(paginationInfo?: Pagination, orgId?: number): Observable<Property[]> {
    const pagination = {
      ...paginationInfo,
      perPage: PropertiesPageSize,
    };

    return this.http.post<Property[]>(this.getBaseUrl(orgId) + 'get', pagination, { withCredentials: true });
  }

  public getPropertyCounts(): Observable<PropertyCount[]> {
    return this.http.get<PropertyCount[]>(this._baseUrl + '/count', { withCredentials: true });
  }

  public getProperty(propertyId: number, orgId?: number): Observable<Property> {
    return this.http.get<Property>(this.getBaseUrl(orgId, propertyId), { withCredentials: true });
  }

  public createProperty(property: any, orgId?: number): Observable<Property> {
    return this.http.post<Property>(this.getBaseUrl(orgId), property, { withCredentials: true });
  }

  public deleteProperty(propertyId: number, orgId?: number): Observable<boolean> {
    return this.http.delete<boolean>(this.getBaseUrl(orgId, propertyId), { withCredentials: true });
  }

  public updateProperty(propertyId: number, property: Partial<Property>, orgId?: number): Observable<Property> {
    return this.http.put<Property>(this.getBaseUrl(orgId, propertyId), property, {
      withCredentials: true,
    });
  }

  public updateCustomFields(
    propertyId: number,
    userProperty: Partial<UserProperty>,
    orgId?: number,
  ): Observable<Property> {
    return this.http.put<Property>(`${this.getBaseUrl(orgId, propertyId)}/customFields`, userProperty, {
      withCredentials: true,
    });
  }

  public exportCsvProperties(filterValue: string, fileName: string, orgId?: number): Observable<ExportJob> {
    const baseUrl = orgId ? this.getBaseUrl(orgId) : this._baseUrl + '/';
    const url = baseUrl + 'csv';

    return this.http.post<ExportJob>(url, { filterValue, fileName }, { withCredentials: true });
  }

  public isDuplicate(addressLabel: string, ownerEmail: string): Observable<boolean> {
    const url = `${this.getBaseUrl()}is-duplicate?addressLabel=${addressLabel}&ownerEmail=${ownerEmail}`;

    return this.http.get<boolean>(url, { withCredentials: true });
  }

  public updateParticipant(
    propertyId: number,
    participant: Partial<User>,
    orgId?: number,
  ): Observable<PropertyParticipants> {
    return this.http.put<PropertyParticipants>(
      `${this.getBaseUrl(orgId, propertyId)}/user-property/${participant.id}`,
      participant,
      { withCredentials: true },
    );
  }

  public reinviteParticipant(propertyId: number, participantId: number, orgId?: number): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.getBaseUrl(orgId, propertyId)}/user-property/${participantId}/reinvite`,
      {},
      { withCredentials: true },
    );
  }

  public deleteParticipant(
    propertyId: number,
    participantId: number,
    orgId?: number,
    deleteReason?: DeleteReason,
  ): Observable<PropertyParticipants> {
    const options: { withCredentials: boolean; params?: HttpParams } = {
      withCredentials: true,
    };

    if (deleteReason) {
      options.params = new HttpParams().append('reason', deleteReason!);
    }

    return this.http.delete<PropertyParticipants>(
      `${this.getBaseUrl(orgId, propertyId)}/user-property/${participantId}`,
      options,
    );
  }

  public findExistingDpe(dpeLegalId: string): Observable<boolean> {
    return this.http.get<boolean>(this._baseUrl + '/doesDpeExist', {
      withCredentials: true,
      params: new HttpParams().append('q', dpeLegalId),
    });
  }

  public getCrmProjects(propertyId: number, orgId?: number): Observable<CrmProject[]> {
    return this.http.get<CrmProject[]>(`${this.getBaseUrl(orgId, propertyId)}/crm/projects`, {
      withCredentials: true,
    });
  }

  public getCrmProject(propertyId: number, crmProjectId: number, orgId?: number): Observable<CrmProject> {
    return this.http.get<CrmProject>(`${this.getBaseUrl(orgId, propertyId)}/crm/projects/${crmProjectId}`, {
      withCredentials: true,
    });
  }

  public getCrmTable(
    propertyId: number,
    crmProjectId: number,
    crmTableId: number,
    pagination?: Pagination,
    orgId?: number,
  ): Observable<CrmTable> {
    const url = `${this.getBaseUrl(orgId, propertyId)}/crm/projects/${crmProjectId}/tables/${crmTableId}`;

    return this.http.post<CrmTable>(url, pagination, { withCredentials: true });
  }

  public uploadFileCrm(
    formData: FormData,
    propertyId: number,
    crmProjectId: number,
    orgId?: number,
  ): Observable<Required<FileFieldValue>> {
    const url = `${this.getBaseUrl(orgId, propertyId)}/crm/projects/${crmProjectId}/upload-file`;

    return this.http.post<Required<FileFieldValue>>(url, formData, { withCredentials: true });
  }

  public saveCrmProjectProperty(
    body: Partial<CrmProjectProperty>,
    propertyId: number,
    crmProjectId: number,
    orgId?: number,
  ): Observable<CrmProject> {
    return this.http.put<CrmProject>(
      `${this.getBaseUrl(orgId, propertyId)}/crm/projects/${crmProjectId}/project-property`,
      body,
      { withCredentials: true },
    );
  }

  public addCrmItem(
    propertyId: number,
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    body: any, // TODO CRM: type this
  ): Observable<CrmTable> {
    return this.http.post<CrmTable>(
      `${this.getBaseUrl(orgId, propertyId)}/crm/projects/${crmProjectId}/tables/${crmTableId}/item`,
      body,
      { withCredentials: true },
    );
  }

  public updateCrmItem(
    propertyId: number,
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    crmItemId: number,
    body: any, // TODO CRM: type this
  ): Observable<CrmItem> {
    return this.http.put<CrmItem>(
      `${this.getBaseUrl(orgId, propertyId)}/crm/projects/${crmProjectId}/tables/${crmTableId}/items/${crmItemId}`,
      body,
      { withCredentials: true },
    );
  }

  /**
   * If no crmItemId, will try to download from a panel crmTable
   */
  public downloadFileCrmItem(
    propertyId: number,
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    crmItemId?: number,
  ): Observable<Blob> {
    let url = `${this.getBaseUrl(orgId, propertyId)}/crm/projects/${crmProjectId}/tables/${crmTableId}`;

    if (crmItemId) {
      url += `/items/${crmItemId}`;
    }

    url += '/download';

    return this.http.get(url, { responseType: 'blob', withCredentials: true });
  }

  public deleteCrmItem(
    propertyId: number,
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    crmItemId: number,
  ): Observable<boolean> {
    return this.http.delete<boolean>(
      `${this.getBaseUrl(orgId, propertyId)}/crm/projects/${crmProjectId}/tables/${crmTableId}/items/${crmItemId}`,
      { withCredentials: true },
    );
  }

  public updatePanel(
    propertyId: number,
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    body: any, // TODO CRM: type this
  ): Observable<CrmItem> {
    return this.http.put<CrmItem>(
      `${this.getBaseUrl(orgId, propertyId)}/crm/projects/${crmProjectId}/tables/${crmTableId}/panel`,
      body,
      { withCredentials: true },
    );
  }

  public getPanelFieldHistory(
    propertyId: number,
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    crmFieldId: number,
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.getBaseUrl(
        orgId,
        propertyId,
      )}/crm/projects/${crmProjectId}/tables/${crmTableId}/panel/history/${crmFieldId}`,
      { withCredentials: true },
    );
  }

  public getRelatedOrgs(orgId?: number): Observable<Org[]> {
    return this.http.get<Org[]>(this.getBaseUrl(orgId) + 'related-orgs', { withCredentials: true });
  }

  public getDepartments(orgId?: number): Observable<{ value: string; label: string }[]> {
    return this.http.get<{ value: string; label: string }[]>(this.getBaseUrl(orgId) + 'departments', {
      withCredentials: true,
    });
  }

  public getCreationMonths(orgId?: number): Observable<{ value: string; label: string }[]> {
    return this.http.get<{ value: string; label: string }[]>(this.getBaseUrl(orgId) + 'creation-months', {
      withCredentials: true,
    });
  }

  private getBaseUrl(orgId?: number, propertyId?: number): string {
    return `${environment.api.baseUrl}${orgId ? '/orgs/' + orgId : ''}/properties/${propertyId ? propertyId : ''}`;
  }
}
