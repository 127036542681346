import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Document } from '../../shared/all.types';

@Injectable()
export class DocumentService {
  constructor(private readonly http: HttpClient) {}

  public getAllDocuments(propertyId: number, orgId?: number): Observable<Document[]> {
    return this.http.get<Document[]>(this.getBaseUrl(orgId, propertyId), { withCredentials: true });
  }

  public downloadDocument(propertyId: number, documentId: number, orgId?: number): Observable<Blob> {
    return this.http.get(`${this.getBaseUrl(orgId, propertyId)}/${documentId}/download`, {
      responseType: 'blob',
      withCredentials: true,
    });
  }

  public updateDocument(
    propertyId: number,
    documentId: number,
    document: Partial<Document>,
    orgId?: number,
  ): Observable<Document> {
    return this.http.put<Document>(`${this.getBaseUrl(orgId, propertyId)}/${documentId}`, document, {
      withCredentials: true,
    });
  }

  public deleteDocument(propertyId: number, documentId: number, orgId?: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.getBaseUrl(orgId, propertyId)}/${documentId}`, {
      withCredentials: true,
    });
  }

  public uploadDocument(formData: FormData, propertyId: number, orgId?: number): Observable<Partial<Document>> {
    return this.http.post<Partial<Document>>(this.getBaseUrl(orgId, propertyId), formData, {
      withCredentials: true,
    });
  }

  public validateDocument(
    documentForm: AbstractControl,
    propertyId: number,
    orgId?: number,
  ): Observable<Partial<Document>> {
    return this.http.post<Partial<Document>>(
      this.getBaseUrl(orgId, propertyId) + '/validate',
      {
        ...documentForm.value,
      },
      {
        withCredentials: true,
      },
    );
  }

  private getBaseUrl(orgId?: number, propertyId?: number): string {
    return `${environment.api.baseUrl}${orgId ? '/orgs/' + orgId : ''}/properties/${
      propertyId ? propertyId : ''
    }/documents`;
  }
}
