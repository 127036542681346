export enum EnergyTag {
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  E = 5,
  F = 6,
  G = 7,
}

export const ENERGY_TAG_MAP = new Map<EnergyTag, string>([
  [EnergyTag.A, 'A'],
  [EnergyTag.B, 'B'],
  [EnergyTag.C, 'C'],
  [EnergyTag.D, 'D'],
  [EnergyTag.E, 'E'],
  [EnergyTag.F, 'F'],
  [EnergyTag.G, 'G'],
]);

export enum EnergyType {
  Electricity = 1,
  TownGas = 2,
  Fuel = 3,
  HeatingNetwork = 4,
  Wood = 5,
  LPG = 6,
  Solar = 7,
  None = 8,
}

export const ENERGY_TYPE_MAP = new Map<EnergyType, string>([
  [EnergyType.Electricity, 'Electricité'],
  [EnergyType.TownGas, 'Gaz de ville'],
  [EnergyType.Fuel, 'Fioul'],
  [EnergyType.HeatingNetwork, 'Réseau de chaleur urbain'],
  [EnergyType.Wood, 'Bois'],
  [EnergyType.LPG, 'Gaz bouteille (GPL)'],
  [EnergyType.Solar, 'Solaire'],
  [EnergyType.None, 'Aucun(e)'],
]);

export enum EnergyDevice {
  Other = 1,
  ElectricBalloon = 2,
  InstantaneousWaterHeater = 3,
  ThermodynamicBalloon = 4,
  HeatPump = 5,
  ElectricBoiler = 6,
  ElectricConvectors = 7,
  RadiantPanels = 8,
  InertiaRadiators = 9,
  AirAirHeatPump = 10,
  AirWaterHeatPump = 11,
  WaterWaterHeatPump = 12,
  IndividualLogBoiler = 13,
  IndividualGranuleBoiler = 14,
  CommunalLogBoiler = 15,
  CommunalGranuleBoiler = 16,
  WoodPlateBoiler = 17,
  LogBoilerStove = 18,
  GranuleBoilerStove = 19,
  CommunalCondensingBoiler = 20,
  CommunalClassicBoiler = 21,
  IndividualCondensingBoiler = 22,
  IndividualClassicBoiler = 23,
  ElectricComplement = 24,
  GasComplement = 25,
  WoodCooker = 26,
  WoodStove = 27,
  GranuleStove = 28,
  RadiantFloorOrCeiling = 29,
  CommunalLowTemperatureBoiler = 30,
  IndividualLowTemperatureBoiler = 31,
  Accumulator = 32,
  ClosedInsetFireplace = 33,
}

export const ENERGY_DEVICE_MAP = new Map<EnergyDevice, string>([
  [EnergyDevice.Other, 'Autre moyen'],
  [EnergyDevice.ElectricBalloon, 'Ballon électrique classique'],
  [EnergyDevice.InstantaneousWaterHeater, 'Chauffe-eau instantané'],
  [EnergyDevice.ThermodynamicBalloon, 'Ballon thermo-dynamique'],
  [EnergyDevice.HeatPump, 'Pompe à chaleur'],
  [EnergyDevice.ElectricBoiler, 'Chaudière électrique'],
  [EnergyDevice.RadiantFloorOrCeiling, 'Plancher ou plafond rayonnant'],
  [EnergyDevice.ElectricConvectors, 'Convecteurs électriques'],
  [EnergyDevice.RadiantPanels, 'Panneaux rayonnants'],
  [EnergyDevice.InertiaRadiators, 'Radiateur à inertie'],
  [EnergyDevice.AirAirHeatPump, 'Pompe à chaleur Air/Air'],
  [EnergyDevice.AirWaterHeatPump, 'Pompe à chaleur Air/Eau'],
  [EnergyDevice.WaterWaterHeatPump, 'Pompe à chaleur Eau/Eau'],
  [EnergyDevice.IndividualLogBoiler, 'Chaudière à bûches individuelle'],
  [EnergyDevice.IndividualGranuleBoiler, 'Chaudière à granulés individuelle'],
  [EnergyDevice.CommunalLogBoiler, 'Chaudière à bûches collective'],
  [EnergyDevice.CommunalGranuleBoiler, 'Chaudière à granulés collective'],
  [EnergyDevice.WoodPlateBoiler, 'Chaudière à plaquettes forestières'],
  [EnergyDevice.LogBoilerStove, 'Poêle bouilleur à bûches'],
  [EnergyDevice.GranuleBoilerStove, 'Poêle bouilleur à granulés'],
  [EnergyDevice.CommunalCondensingBoiler, 'Chaudière à condensation collective'],
  [EnergyDevice.CommunalLowTemperatureBoiler, 'Chaudière basse température collective'],
  [EnergyDevice.CommunalClassicBoiler, 'Chaudière classique collective'],
  [EnergyDevice.IndividualCondensingBoiler, 'Chaudière à condensation individuelle'],
  [EnergyDevice.IndividualLowTemperatureBoiler, 'Chaudière basse température individuelle'],
  [EnergyDevice.IndividualClassicBoiler, 'Chaudière classique individuelle'],
  [EnergyDevice.ElectricComplement, 'Complément électrique'],
  [EnergyDevice.GasComplement, 'Complément gaz naturel'],
  [EnergyDevice.WoodCooker, 'Cuisinière bois'],
  [EnergyDevice.WoodStove, 'Poêle à bûches'],
  [EnergyDevice.GranuleStove, 'Poêle à granulés'],
  [EnergyDevice.Accumulator, 'Accumulateur'],
  [EnergyDevice.ClosedInsetFireplace, 'Insert ou cheminée'],
]);

export enum EnergyTimeline {
  Before2000 = 1,
  Between2001And2014 = 2,
  After2015 = 3,
}

export const ENERGY_TIMELINE_MAP = new Map<EnergyTimeline, string>([
  [EnergyTimeline.Before2000, 'Avant 2000'],
  [EnergyTimeline.Between2001And2014, '2001 à 2014'],
  [EnergyTimeline.After2015, 'Après 2015'],
]);

export const heatTypeToDevice: Map<EnergyType, EnergyDevice[]> = new Map([
  [
    EnergyType.Electricity,
    [
      EnergyDevice.ElectricConvectors,
      EnergyDevice.RadiantPanels,
      EnergyDevice.InertiaRadiators,
      EnergyDevice.ElectricBoiler,
      EnergyDevice.RadiantFloorOrCeiling,
      EnergyDevice.AirAirHeatPump,
      EnergyDevice.AirWaterHeatPump,
      EnergyDevice.WaterWaterHeatPump,
      EnergyDevice.Other,
    ],
  ],
  [
    EnergyType.Wood,
    [
      EnergyDevice.IndividualLogBoiler,
      EnergyDevice.IndividualGranuleBoiler,
      EnergyDevice.CommunalLogBoiler,
      EnergyDevice.CommunalGranuleBoiler,
      EnergyDevice.WoodPlateBoiler,
      EnergyDevice.WoodCooker,
      EnergyDevice.WoodStove,
      EnergyDevice.GranuleStove,
      EnergyDevice.ClosedInsetFireplace,
      EnergyDevice.Other,
    ],
  ],
  [
    EnergyType.Fuel,
    [
      EnergyDevice.CommunalCondensingBoiler,
      EnergyDevice.CommunalLowTemperatureBoiler,
      EnergyDevice.CommunalClassicBoiler,
      EnergyDevice.IndividualCondensingBoiler,
      EnergyDevice.IndividualLowTemperatureBoiler,
      EnergyDevice.IndividualClassicBoiler,
      EnergyDevice.Other,
    ],
  ],
  [
    EnergyType.TownGas,
    [
      EnergyDevice.CommunalCondensingBoiler,
      EnergyDevice.CommunalLowTemperatureBoiler,
      EnergyDevice.CommunalClassicBoiler,
      EnergyDevice.IndividualCondensingBoiler,
      EnergyDevice.IndividualLowTemperatureBoiler,
      EnergyDevice.IndividualClassicBoiler,
      EnergyDevice.Other,
    ],
  ],
  [
    EnergyType.LPG,
    [
      EnergyDevice.CommunalCondensingBoiler,
      EnergyDevice.CommunalClassicBoiler,
      EnergyDevice.IndividualCondensingBoiler,
      EnergyDevice.IndividualClassicBoiler,
      EnergyDevice.Other,
    ],
  ],
  [EnergyType.HeatingNetwork, []],
  [EnergyType.Solar, []],
]);

export const waterTypeToDevice: Map<EnergyType, EnergyDevice[]> = new Map([
  [
    EnergyType.Electricity,
    [
      EnergyDevice.ElectricBalloon,
      EnergyDevice.InstantaneousWaterHeater,
      EnergyDevice.ThermodynamicBalloon,
      EnergyDevice.HeatPump,
      EnergyDevice.ElectricBoiler,
      EnergyDevice.Other,
    ],
  ],
  [
    EnergyType.Wood,
    [
      EnergyDevice.IndividualLogBoiler,
      EnergyDevice.IndividualGranuleBoiler,
      EnergyDevice.CommunalLogBoiler,
      EnergyDevice.CommunalGranuleBoiler,
      EnergyDevice.WoodPlateBoiler,
      EnergyDevice.LogBoilerStove,
      EnergyDevice.GranuleBoilerStove,
      EnergyDevice.Other,
    ],
  ],
  [
    EnergyType.Fuel,
    [
      EnergyDevice.CommunalCondensingBoiler,
      EnergyDevice.CommunalLowTemperatureBoiler,
      EnergyDevice.CommunalClassicBoiler,
      EnergyDevice.IndividualCondensingBoiler,
      EnergyDevice.IndividualLowTemperatureBoiler,
      EnergyDevice.IndividualClassicBoiler,
      EnergyDevice.Other,
    ],
  ],
  [
    EnergyType.TownGas,
    [
      EnergyDevice.CommunalCondensingBoiler,
      EnergyDevice.CommunalLowTemperatureBoiler,
      EnergyDevice.CommunalClassicBoiler,
      EnergyDevice.IndividualCondensingBoiler,
      EnergyDevice.IndividualLowTemperatureBoiler,
      EnergyDevice.IndividualClassicBoiler,
      EnergyDevice.InstantaneousWaterHeater,
      EnergyDevice.Accumulator,
      EnergyDevice.Other,
    ],
  ],
  [
    EnergyType.LPG,
    [
      EnergyDevice.CommunalCondensingBoiler,
      EnergyDevice.CommunalClassicBoiler,
      EnergyDevice.IndividualCondensingBoiler,
      EnergyDevice.IndividualClassicBoiler,
      EnergyDevice.InstantaneousWaterHeater,
      EnergyDevice.Accumulator,
      EnergyDevice.Other,
    ],
  ],
  [EnergyType.HeatingNetwork, []],
  [EnergyType.Solar, [EnergyDevice.ElectricComplement, EnergyDevice.GasComplement, EnergyDevice.Other]],
]);

export enum VentilationType {
  None = 1,
  ConstantSimpleFlow = 2,
  VariableSimpleFlow = 3,
  PassiveDualFlow = 4,
  ThermodynamicDualFlow = 5,
  Other = 6,
  SwitchSimpleFlow = 7,
}

export const VENTILATION_TYPE_MAP = new Map<VentilationType, string>([
  [VentilationType.None, 'Pas de ventilation mécanique'],
  [VentilationType.SwitchSimpleFlow, 'VMC simple flux sur interrupteur'],
  [VentilationType.ConstantSimpleFlow, 'VMC simple flux constante'],
  [VentilationType.VariableSimpleFlow, 'VMC simple flux à vitesse variable'],
  [VentilationType.PassiveDualFlow, 'VMC double flux passive'],
  [VentilationType.ThermodynamicDualFlow, 'VMC double flux thermo-dynamique'],
  [VentilationType.Other, 'Autre moyen'],
]);

export enum AirConditioningType {
  None = 1,
  MobileOrMonobloc = 2,
  HeatPump = 3,
}

export const AIR_CONDITIONING_TYPE_MAP = new Map<AirConditioningType, string>([
  [AirConditioningType.None, 'Pas de climatisation'],
  [AirConditioningType.MobileOrMonobloc, 'Climatiseur mobile ou monobloc'],
  [AirConditioningType.HeatPump, 'Pompe à chaleur réversible'],
]);

export enum CooktopType {
  Electric = 1,
  Induction = 2,
  LPG = 3,
  TownGas = 4,
  Wood = 5,
  None = 6,
}

export const COOKTOP_TYPE_MAP = new Map<CooktopType, string>([
  [CooktopType.Electric, 'Plaques électriques'],
  [CooktopType.Wood, 'Cuisinière bois'],
  [CooktopType.Induction, 'Plaques à induction'],
  [CooktopType.LPG, 'Gazinière au GPL'],
  [CooktopType.TownGas, 'Gazinière au gaz de ville'],
  [CooktopType.None, 'Aucune'],
]);

export enum OvenType {
  Electric = 1,
  LPG = 2,
  TownGas = 3,
  Wood = 4,
  None = 5,
}

export const OVEN_TYPE_MAP = new Map<OvenType, string>([
  [OvenType.Electric, 'Four électrique'],
  [OvenType.LPG, 'Four au GPL'],
  [OvenType.TownGas, 'Four au gaz de ville'],
  [OvenType.Wood, 'Cuisinière bois'],
  [OvenType.None, 'Aucune'],
]);

export enum BulbType {
  Incandescent = 1,
  Fluorescent = 2,
  LedFluorescent = 3,
  Led = 4,
}

export const BULB_TYPE_MAP = new Map<BulbType, string>([
  [BulbType.Incandescent, 'Majoritairement incandescent'],
  [BulbType.Fluorescent, 'Majoritairement fluocompacte'],
  [BulbType.LedFluorescent, 'Mixte LED et fluocompacte'],
  [BulbType.Led, 'Majoritairement LED'],
]);

export enum HousingType {
  Apartment = 1,
  IndividualHouse = 2,
}

export const HOUSING_TYPE_MAP = new Map<HousingType, string>([
  [HousingType.Apartment, 'Appartement'],
  [HousingType.IndividualHouse, 'Maison individuelle'],
]);

export enum RentalType {
  Empty = 1,
  Furnished = 2,
}

export const RENTAL_TYPE_MAP = new Map<RentalType, string>([
  [RentalType.Empty, 'Location nue'],
  [RentalType.Furnished, 'Location meublée'],
]);

export enum HousingSize {
  Studio = 1,
  T1 = 2,
  T2 = 3,
  T2Bis = 4,
  T3 = 5,
  T3Bis = 6,
  T4 = 7,
  T3T4 = 8,
  T5 = 9,
  T6OrMore = 10,
}

export const HOUSING_SIZE_MAP = new Map<HousingSize, { label: string; desc: string }>([
  [
    HousingSize.Studio,
    {
      label: 'Studio',
      desc: "Un studio est un logement T1 ou 1 pièce avec une cuisine non séparée. Il n'y a pas de chambre séparée.",
    },
  ],
  [
    HousingSize.T1,
    {
      label: 'T1',
      desc: "Un logement de 1 pièce principale (à la fois chambre et salon) + 1 cuisine + 1 salle de bains (avec WC séparés ou inclus). Il n'y a pas de chambre séparée.",
    },
  ],
  [
    HousingSize.T2,
    {
      label: 'T2',
      desc: 'Un logement de 2 pièces (1 chambre + 1 salon) + 1 cuisine + 1 salle de bains (avec WC séparés ou inclus)',
    },
  ],
  [
    HousingSize.T2Bis,
    {
      label: 'T2 bis',
      desc: "Un logement de 2 pièces (1 chambre + 1 salon) dont l'une est suffisamment grande pour être séparée en deux zones distinctes + 1 cuisine + 1 salle de bains (avec WC séparés ou inclus)",
    },
  ],
  [
    HousingSize.T3,
    {
      label: 'T3',
      desc: 'Un logement de 3 pièces (2 chambres + 1 salon) + 1 cuisine + 1 salle de bains (avec WC séparés ou inclus)',
    },
  ],
  [
    HousingSize.T3Bis,
    {
      label: 'T3 bis',
      desc: "Un logement de 3 pièces (2 chambres + 1 salon) dont l'une est suffisamment grande pour être séparée en deux zones distinctes + 1 cuisine + 1 salle de bains (avec WC séparés ou inclus)",
    },
  ],
  [
    HousingSize.T4,
    {
      label: 'T4',
      desc: 'Un logement de 4 pièces (3 chambres + 1 salon/séjour OU 2 chambres + 1 salon + 1 salle à manger) + 1 cuisine + 1 salle de bains (avec WC séparés ou inclus)',
    },
  ],
  [
    HousingSize.T3T4,
    {
      label: 'T3 T4',
      desc: 'Un logement T4 transformé en T3 en réunissant deux pièces pour en faire 1 grande',
    },
  ],
  [
    HousingSize.T5,
    {
      label: 'T5',
      desc: 'Un logement de 5 pièces (4 chambres + 1 salon/séjour OU 3 chambres + 1 salon + 1 salle à manger) + 1 cuisine + 1 salle de bains ou + (avec WC séparés ou inclus)',
    },
  ],
  [
    HousingSize.T6OrMore,
    {
      label: 'T6 ou plus',
      desc: "Un logement de 6 pièces (5 chambres + 1 salon) dont l'une est suffisamment grande pour être séparée en deux zones distinctes + 1 cuisine + 1 salle de bains (avec WC séparés ou inclus)",
    },
  ],
]);

export enum Occupancy {
  OwnerOccupant = 1,
  Tenant = 2,
  Waiting = 3,
  VacationRental = 4,
  Unoccupied = 5,
}

export const OCCUPANCY_MAP = new Map<Occupancy, string>([
  [Occupancy.OwnerOccupant, 'Propriétaire occupant'],
  [Occupancy.Tenant, 'Locataire en cours de bail'],
  [Occupancy.Waiting, 'En attente de locataire'],
  [Occupancy.VacationRental, 'Location saisonnière'],
  [Occupancy.Unoccupied, 'Logement vacant'],
]);

export enum SharedWalls {
  None = 1,
  One = 2,
  TwoOrMore = 3,
}

export const SHARED_WALLS_MAP = new Map<SharedWalls, string>([
  [SharedWalls.None, 'Aucun mur en mitoyenneté'],
  [SharedWalls.One, 'Un mur en mitoyenneté'],
  [SharedWalls.TwoOrMore, 'Deux murs ou plus en mitoyenneté'],
]);

export enum BuildingHomes {
  ManyWithCoproperty = 1,
  ManyWithoutCoproperty = 2,
  Mine = 3,
}

export const BUILDING_HOMES_MAP = new Map<BuildingHomes, string>([
  [BuildingHomes.ManyWithCoproperty, 'Plusieurs en copropriété'],
  [BuildingHomes.ManyWithoutCoproperty, 'Plusieurs sans copropriété'],
  [BuildingHomes.Mine, 'Mon logement uniquement'],
]);

export enum ConstructionPeriod {
  Before1949 = 1,
  Between1949And1960 = 2,
  Between1961And1974 = 3,
  Between1975And1993 = 4,
  Between1994And2000 = 5,
  Between2001And2010 = 6,
  After2011 = 7,
}

export const CONSTRUCTION_PERIOD_MAP = new Map<ConstructionPeriod, string>([
  [ConstructionPeriod.Before1949, 'Avant 1949'],
  [ConstructionPeriod.Between1949And1960, 'de 1949 à 1960'],
  [ConstructionPeriod.Between1961And1974, 'de 1961 à 1974'],
  [ConstructionPeriod.Between1975And1993, 'de 1975 à 1993'],
  [ConstructionPeriod.Between1994And2000, 'de 1994 à 2000'],
  [ConstructionPeriod.Between2001And2010, 'de 2001 à 2010'],
  [ConstructionPeriod.After2011, 'Après 2011'],
]);

export enum NrjType {
  ENEDIS = 1,
  GRDF = 2,
}

export enum NrjStatus {
  OnGoing = 1, // We already called the NRJ API to retrieve the access right id
  MissingMeterId = 2,
  MissingAccessRightId = 3,
  NrjError = 4, // We have an access right but for some reason we can't call Enedis or GRDF
}

export enum EnergyShare {
  None = 1,
  Yearly = 2,
  Monthly = 3,
  Daily = 4,
  Hourly = 5,
}

export const ENERGY_SHARE_MAP = new Map<EnergyShare, string>([
  [EnergyShare.None, 'Aucun partage'],
  [EnergyShare.Yearly, 'Données annuelles'],
  [EnergyShare.Monthly, 'Données mensuelles'],
  [EnergyShare.Daily, 'Données quotidiennes'],
  [EnergyShare.Hourly, 'Données horaires'],
]);

export enum PropertyManagerType {
  None = 1,
  Undefined = 2,
  PropertyManager = 3,
  Org = 4,
}

export const PROPERTY_MANAGER_TYPE_MAP = new Map<PropertyManagerType, string>([
  [PropertyManagerType.None, "Pas d'intermédiaire"],
  [PropertyManagerType.Undefined, 'Intermédiaire non déterminé'],
  [PropertyManagerType.PropertyManager, 'Gestionnaire immobilier'],
  [PropertyManagerType.Org, 'Agence immobilière'],
]);

export enum PropertyWallInsulation {
  Insufficient = 1,
  Average = 2,
  Good = 3,
  VeryGood = 4,
}

export const PROPERTY_WALL_INSULATION_MAP = new Map<PropertyWallInsulation, string>([
  [PropertyWallInsulation.Insufficient, 'Insuffisante'],
  [PropertyWallInsulation.Average, 'Moyenne'],
  [PropertyWallInsulation.Good, 'Bonne'],
  [PropertyWallInsulation.VeryGood, 'Très bonne'],
]);

export enum PropertyLowFloorType {
  OpenGround = 1,
  Crawlspace = 2,
  AdjoiningHeatedSpace = 3,
  AdjoiningUnheatedSpace = 4,
}

export const PROPERTY_LOW_FLOOR_TYPE_MAP = new Map<PropertyLowFloorType, string>([
  [PropertyLowFloorType.OpenGround, 'Terre plein'],
  [PropertyLowFloorType.Crawlspace, 'Vide sanitaire'],
  [PropertyLowFloorType.AdjoiningHeatedSpace, "Mitoyen d'un espace chauffé"],
  [PropertyLowFloorType.AdjoiningUnheatedSpace, "Mitoyen d'un espace non chauffé"],
]);

export enum PropertyLowFloorInsulation {
  Insufficient = 1,
  Average = 2,
  Good = 3,
  VeryGood = 4,
}

export const PROPERTY_LOW_FLOOR_INSULATION_MAP = new Map<PropertyLowFloorInsulation, string>([
  [PropertyLowFloorInsulation.Insufficient, 'Insuffisante'],
  [PropertyLowFloorInsulation.Average, 'Moyenne'],
  [PropertyLowFloorInsulation.Good, 'Bonne'],
  [PropertyLowFloorInsulation.VeryGood, 'Très bonne'],
]);

export enum PropertyHighFloorType {
  ConvertedAttic = 1,
  LostAttic = 2,
  RoofTerrace = 3,
  AdjoiningHeatedSpace = 4,
  AdjoiningUnheatedSpace = 5,
}

export const PROPERTY_HIGH_FLOOR_TYPE_MAP = new Map<PropertyHighFloorType, string>([
  [PropertyHighFloorType.ConvertedAttic, 'Combles aménagés'],
  [PropertyHighFloorType.LostAttic, 'Combles perdus'],
  [PropertyHighFloorType.RoofTerrace, 'Toiture terrasse'],
  [PropertyHighFloorType.AdjoiningHeatedSpace, "Mitoyen d'un espace chauffé"],
  [PropertyHighFloorType.AdjoiningUnheatedSpace, "Mitoyen d'un espace non chauffé"],
]);

export enum PropertyHighFloorInsulation {
  Insufficient = 1,
  Average = 2,
  Good = 3,
  VeryGood = 4,
}

export const PROPERTY_HIGH_FLOOR_INSULATION_MAP = new Map<PropertyHighFloorInsulation, string>([
  [PropertyHighFloorInsulation.Insufficient, 'Insuffisante'],
  [PropertyHighFloorInsulation.Average, 'Moyenne'],
  [PropertyHighFloorInsulation.Good, 'Bonne'],
  [PropertyHighFloorInsulation.VeryGood, 'Très bonne'],
]);

export enum PropertyJoineryQuality {
  Insufficient = 1,
  Average = 2,
  Good = 3,
  VeryGood = 4,
}

export const PROPERTY_JOINERY_QUALITY_MAP = new Map<PropertyJoineryQuality, string>([
  [PropertyJoineryQuality.Insufficient, 'Insuffisante'],
  [PropertyJoineryQuality.Average, 'Moyenne'],
  [PropertyJoineryQuality.Good, 'Bonne'],
  [PropertyJoineryQuality.VeryGood, 'Très bonne'],
]);

export enum PropertyThermostat {
  None = 1,
  NotProgrammable = 2,
  Programmable = 3,
  ConnectedBasic = 4,
  ConectedIntelligent = 5,
}

export const PROPERTY_THERMOSTAT_MAP = new Map<PropertyThermostat, string>([
  [PropertyThermostat.None, 'Pas de thermostat individuel'],
  [PropertyThermostat.NotProgrammable, 'Thermostat(s) non programmable(s)'],
  [PropertyThermostat.Programmable, 'Thermostat(s) programmable(s)'],
  [PropertyThermostat.ConnectedBasic, 'Thermostat connecté basique'],
  [PropertyThermostat.ConectedIntelligent, 'Thermostat connecté intelligent'],
]);

export enum PropertyUpkeep {
  None = 1,
  WithoutContract = 2,
  WithContract = 3,
}

export const PROPERTY_HEATING_UPKEEP_MAP = new Map<PropertyUpkeep, string>([
  [PropertyUpkeep.None, 'Aucun entretien réalisé'],
  [PropertyUpkeep.WithoutContract, 'Entretien réalisé sans contrat'],
  [PropertyUpkeep.WithContract, 'Entretien réalisé avec contrat'],
]);

export const PROPERTY_VENTILATION_UPKEEP_MAP = new Map<PropertyUpkeep, string>([
  [PropertyUpkeep.None, 'Aucun entretien réalisé'],
  [PropertyUpkeep.WithoutContract, 'Entretien réalisé sans contrat'],
  [PropertyUpkeep.WithContract, 'Entretien réalisé avec contrat'],
]);

export enum PropertyAirInletUpkeep {
  None = 1,
  InletBlocked = 2,
  InletCleaned = 3,
}

export const PROPERTY_AIR_INLET_UPKEEP_MAP = new Map<PropertyAirInletUpkeep, string>([
  [PropertyAirInletUpkeep.None, 'Aucun entretien réalisé'],
  [PropertyAirInletUpkeep.InletBlocked, 'Entrées d’air bouchées'],
  [PropertyAirInletUpkeep.InletCleaned, 'Entrées d’air entretenues'],
]);

export enum PropertyNaturalAiring {
  Manual = 1,
  DailyNoWinter = 2,
  DailyWithWinter = 3,
}

export const PROPERTY_NATURAL_AIRING_UPKEEP_MAP = new Map<PropertyNaturalAiring, string>([
  [PropertyNaturalAiring.Manual, 'Aucune aération manuelle'],
  [PropertyNaturalAiring.DailyNoWinter, 'Ouverture quotidienne sauf en hiver'],
  [PropertyNaturalAiring.DailyWithWinter, 'Ouverture quotidienne y compris en hiver'],
]);

export const HeatUpkeepDevices = [
  EnergyDevice.ElectricBoiler,
  EnergyDevice.IndividualLogBoiler,
  EnergyDevice.IndividualGranuleBoiler,
  EnergyDevice.AirAirHeatPump,
  EnergyDevice.AirWaterHeatPump,
  EnergyDevice.WaterWaterHeatPump,
  EnergyDevice.WoodCooker,
  EnergyDevice.WoodStove,
  EnergyDevice.GranuleStove,
  EnergyDevice.ClosedInsetFireplace,
  EnergyDevice.IndividualClassicBoiler,
  EnergyDevice.IndividualCondensingBoiler,
  EnergyDevice.IndividualLowTemperatureBoiler,
];

export const WaterVolumeDevices = [
  EnergyDevice.ElectricBalloon,
  EnergyDevice.ThermodynamicBalloon,
  EnergyDevice.HeatPump,
  EnergyDevice.IndividualLogBoiler,
  EnergyDevice.IndividualGranuleBoiler,
  EnergyDevice.LogBoilerStove,
  EnergyDevice.GranuleBoilerStove,
  EnergyDevice.Accumulator,
  EnergyDevice.ElectricComplement,
  EnergyDevice.GasComplement,
  EnergyDevice.Other,
];

export enum ParticipantType {
  Tenant = 1,
  Owner = 2,
  PropertyManager = 3,
  RenovationOperator = 4,
}

export const PARTICIPANT_ROLE_MAP = new Map<ParticipantType, string>([
  [ParticipantType.Tenant, 'Locataire principal'],
  [ParticipantType.Owner, 'Propriétaire'],
  [ParticipantType.PropertyManager, 'Gestionnaire immobilier'],
  [ParticipantType.RenovationOperator, 'Opérateur PHILOGI'],
]);

export enum DisplayParticipantType {
  Organization = 1,
  Owner = 2,
  Tenant = 3,
  EmptyTenant = 4,
  PropertyManager = 5,
  RenovationOperator = 6,
  ParentOrg = 7,
  ProgramManager = 8,
}

export const DISPLAY_PARTICIPANT_TYPE_MAP = new Map<DisplayParticipantType, string>([
  [DisplayParticipantType.Owner, 'Propriétaire'],
  [DisplayParticipantType.Tenant, 'Locataire'],
  [DisplayParticipantType.Organization, 'Référent'],
  [DisplayParticipantType.PropertyManager, 'Gestionnaire immobilier'],
  [DisplayParticipantType.RenovationOperator, 'Opérateur PHILOGI'],
  [DisplayParticipantType.ParentOrg, 'Organisation'],
  [DisplayParticipantType.ProgramManager, 'Programme suivi'],
]);

export enum PropertyDpeUpdateReason {
  WasManuallyEntered = 1,
  NewDpeAfterRenovations = 2,
  NewDpeWithoutRenovations = 3,
  WrongDpe = 4,
}

export const PROPERTY_DPE_UPDATE_REASON_MAP = new Map<PropertyDpeUpdateReason, string>([
  [PropertyDpeUpdateReason.WasManuallyEntered, 'Le DPE avait été renseigné manuellement'],
  [PropertyDpeUpdateReason.NewDpeAfterRenovations, 'Un nouveau DPE a été réalisé après des travaux'],
  [PropertyDpeUpdateReason.NewDpeWithoutRenovations, 'Un nouveau DPE a été réalisé sans travaux'],
  [PropertyDpeUpdateReason.WrongDpe, "L'ancien DPE ne concernait pas ce logement"],
]);

export enum ExportJobStatus {
  OnGoing = 1,
  Done = 2,
  Error = 3,
}
