import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PropertyParticipants, User } from '../../shared/all.types';

@Injectable()
export class ParticipantService {
  constructor(private readonly http: HttpClient) {}

  public getParticipants(propertyId: number, orgId?: number): Observable<PropertyParticipants> {
    return this.http.get<PropertyParticipants>(this.getBaseUrl(orgId, propertyId), {
      withCredentials: true,
    });
  }

  public updateParticipant(
    propertyId: number,
    participant: Partial<User>,
    orgId?: number,
  ): Observable<PropertyParticipants> {
    return this.http.put<PropertyParticipants>(`${this.getBaseUrl(orgId, propertyId)}/${participant.id}`, participant, {
      withCredentials: true,
    });
  }

  public addParticipant(
    propertyId: number,
    participant: Partial<User>,
    orgId?: number,
  ): Observable<PropertyParticipants> {
    return this.http.post<PropertyParticipants>(this.getBaseUrl(orgId, propertyId), participant, {
      withCredentials: true,
    });
  }

  public deleteParticipant(
    propertyId: number,
    participantId: number,
    orgId?: number,
  ): Observable<PropertyParticipants> {
    return this.http.delete<PropertyParticipants>(`${this.getBaseUrl(orgId, propertyId)}/${participantId}`, {
      withCredentials: true,
    });
  }

  private getBaseUrl(orgId?: number, propertyId?: number): string {
    return `${environment.api.baseUrl}${orgId ? '/orgs/' + orgId : ''}/properties/${
      propertyId ? propertyId : ''
    }/participants`;
  }
}
